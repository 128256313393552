import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from './pages/Login.vue';
import Register from './pages/Register.vue';
import Reset from './pages/Reset.vue';
import ChangePassword from './pages/ChangePassword.vue';
import ConfirmEmail from './pages/ConfirmEmail.vue';
import TwoStepVerification from './pages/TwoStepVerification.vue';
import ConfirmRegistration from './pages/ConfirmRegistration';
import { cloneDeep } from 'lodash'
import sessionKeys from '@/sessionStorageKeys'
import busEvents from '@/busEvents'
import i18n from '@/i18n/language';

Vue.use(VueRouter);
const vue = new Vue();

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/login',
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        label: 'route__login',
      }
    },
    {
      path: '/register',
      component: Register,
      meta: {
        label: 'route__registration',
      }
    },
    {
      path: '/reset',
      name: 'reset',
      component: Reset,
      meta: {
        label: 'route__reset_password',
      }
    },
    {
      path: '/reset/:token',
      component: ChangePassword
    },
    {
      path: '/register/confirm/:token?',
      component: ConfirmEmail
    },
    {
      path: '*',
      redirect: '/login'
    },
    {
      path: '/login/two-step-verification',
      name: '2fa',
      component: TwoStepVerification,
      meta: {
        label: 'route__2fa',
      }
    },
    {
      path: '/confirm/:token',
      name: 'register-confirm',
      component: ConfirmRegistration,
    },
  ]
});

router.beforeEach((to, from, next) => {
  const pageLabel = to.meta && to.meta.label
  const documentTitle = pageLabel ? `: ${i18n.t(pageLabel)}` : '';
  setDocumentTitle(`${window.APP_DATA.appName}${documentTitle}`);

  const { token, email, sessionExpired } = to.query

  if (token || email || sessionExpired) {
    const nextRoute = cloneRoute(to)
    processRouteQuery(nextRoute)

    next(nextRoute)
  } else {
    next()
  }
})

const setDocumentTitle = title => {
  document.title = title;
};

const cloneRoute = route => {
  const {
    path,
    meta,
    name,
    params,
    query
  } = route

  return {
    path,
    name,
    params: cloneDeep(params),
    meta: cloneDeep(meta),
    query: cloneDeep(query)
  }
}

const processRouteQuery = route => {
  const { token, email, sessionExpired } = route.query

  if (token) {
    saveToSessionStorage(sessionKeys.TOKEN, token);
    delete route.query.token
  }

  if (email) {
    saveToSessionStorage(sessionKeys.EMAIL, email);
    delete route.query.email
  }

  if (sessionExpired) {
    setTimeout(() => emitBusEvent(busEvents.SESSION_EXPIRED), 0)
    delete route.query.sessionExpired
  }
}

const saveToSessionStorage = (key, value) => {
  vue.$session.set(key, value)
}

const emitBusEvent = (event, payload) => {
  Vue.bus.emit(event, payload)
}

export default router;
