<template>
  <span class="notifications-handler"></span>
</template>

<script>
import busEvents from '@/busEvents'

export default {
  created () {
    this.$bus.on(busEvents.SESSION_EXPIRED, this.handleSessionExpired)
  },

  methods: {
    handleSessionExpired () {
      this.infoNotification({
        title: this.$t('session_expired__title'),
        message: this.$t('session_expired__message'),
      })
    },

    infoNotification({ title , message,}) {
      this.$notify({
        type: "info",
        title,
        message,
        duration: 10000,
        showClose: true
      });
    },
  },  
}
</script>

<style lang="scss">
  .notifications-handler {
    display: none;
  }
</style>
