import 'element-theme-chalk';
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import router from './router';
import App from './pages/App.vue';
import './env';
import {
  Button,
  Input,
  Form,
  FormItem,
  Row,
  Col,
  Loading,
  Message,
  Checkbox,
  Notification,
} from 'element-ui';

import VueCookies from 'vue-cookies';
import i18n from './i18n/language';
import vueMixin from './mixin';
import directive from './components/loading/src/vue-loading-directive';
import service from './components/loading/src/index';
import SessionStorage from 'vue-sessionstorage';
import VueBus from 'vue-bus';
import sEmitter from 'storage-emitter';
import lsEvents from './lsEvents';
import sentry from './sentry';
import '@/assets/styles/base.scss';

sentry(router);

sEmitter.on(lsEvents.DASHBOARD_INITIALIZED, () => {
  window.location.href = '/';
});

Vue.use(Input);
Vue.use(Button);
Vue.use(Form);
Vue.use(Checkbox);
Vue.use(FormItem);
Vue.use(Row);
Vue.use(Col);

Vue.use(VueCookies);
Vue.use(Loading);
Vue.use(directive);
Vue.use(SessionStorage);
Vue.use(VueBus);

Vue.prototype.$message = Message;
Vue.prototype.$loading = service;
Vue.prototype.$notify = Notification;
vueMixin.init();

let hostName = window.location.protocol + '//' + window.location.host;
const AxiosObj = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL || hostName,
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
});
Vue.use(VueAxios, AxiosObj);

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app');

Vue.axios.interceptors.request.use(config => {
  config.headers['X-Requested-With'] = 'XMLHttpRequest';

  return config;
});
