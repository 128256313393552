<template>
  <div>
    <ElRow type="flex" justify="center">
      <div class="login-form">
        <div v-if="sendEmail" class="login-form__success">
          <SuccessButton></SuccessButton>
          <ElRow type="flex" justify="center" class="login-form__success-row">
            <ElCol :span="24" class="text-center">
              <span class="text-center success-message">
                <br />
                {{ $t('success') }}
                <br />
                <br />
                {{ $t('send_email', { email: form.email }) }}
              </span>
            </ElCol>
          </ElRow>
        </div>

        <div v-else>
          <ElRow type="flex" justify="center">
            <ElCol class="login-title">{{ $t('recovery_password') }}</ElCol>
          </ElRow>
          <Transition name="el-fade-in">
            <ElRow style="margin: 0 0 0 0" v-show="fail">
              <ElCol type="flex" justify="center" class="login-form__fail">
                <p class="fail-login">{{ $t('send_email_error') }}</p>
              </ElCol>
            </ElRow>
          </Transition>
          <ElRow class="login-form__row">
            <ElForm
              ref="form"
              :model="form"
              :rules="rules"
              class="login-form__form"
              @submit.native.prevent
              @keydown.native.enter="submitForm('form')"
            >
              <ElFormItem prop="email">
                <ElInput id="email" v-model="form.email" placeholder="Email" />
              </ElFormItem>
              <ElFormItem class="login-form__form-item">
                <ElRow type="flex" justify="center" class="login-form__form-item__row">
                  <ElCol :span="24">
                    <ElButton
                      id="reset"
                      :loading="loading"
                      type="primary"
                      class="enter-button login-form__button"
                      @click="submitForm('form')"
                    >
                      {{ $t('reset_password') }}
                    </ElButton>

                    <VueRecaptcha
                      v-if="globalRecaptcha.enabled && globalRecaptcha.key"
                      ref="invisibleRecaptcha"
                      :sitekey="globalRecaptcha.key"
                      size="invisible"
                      loadRecaptchaScript
                      @verify="resetPassword"
                      @expired="submitForm"
                    />
                  </ElCol>
                </ElRow>
              </ElFormItem>
            </ElForm>
          </ElRow>
        </div>
        <ElRow type="flex" justify="center">
          <ElCol class="text-center form-footer-text-block">
            {{ $t('return_to_login') }}
            <RouterLink to="/login">{{ $t('sign_in') }}</RouterLink>
          </ElCol>
        </ElRow>
      </div>
    </ElRow>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import SuccessButton from '../components/Success.vue';

export default {
  components: { VueRecaptcha, SuccessButton },
  i18n: {
    messages: {
      ru: {
        success: 'Поздравляем!',
        sign_in: 'страницу Входa',
        reset_password: 'Сбросить пароль',
        recovery_password: 'Восстановление пароля',
        return_to_login: 'Вернуться на ',
        send_email_error:
          'Возникла ошибка при сбросе пароля. Попробуйте еще раз позже или обратитесь к вашему администратору.',
        send_email:
          'Если пользователь "{email}" существует - мы отправим письмо с инструкцией по сбросу пароля. Если вы не получили письмо в течении нескольких минут - пожалуйста, проверьте: Спам и Корзину, корректность email-адреса или свяжитесь с вашим администратором.',
      },
      uk: {
        success: 'Вітаємо!',
        sign_in: 'сторінку Входу',
        reset_password: 'Скинути пароль',
        recovery_password: 'Відновлення пароля',
        return_to_login: 'Повернутися на ',
        send_email_error:
          'Виникла помилка при скиданні пароля. Спробуйте ще раз пізніше або зверніться до вашого адміністратора.',
        send_email:
          'Якщо користувач "{email}" існує - ми надішлемо листа з інструкцією зі скидання пароля. Якщо ви не отримали лист протягом декількох хвилин - будь ласка, перевірте: Спам та Корзину, коректність email-адреси або зв\'яжіться з вашим адміністратором.',
      },
      en: {
        success: 'Success!',
        sign_in: 'Login page',
        reset_password: 'Reset password',
        recovery_password: 'Password recovery',
        return_to_login: 'Return to the ',
        send_email_error:
          'An error occurred while resetting the password. Please try again later or contact your administrator.',
        send_email:
          'If user "{email}" exists - we will send an email with reset instructions. If you do not receive the message within a few minutes, please check spam or junk email folder, check email correctness or contact your administrator.',
      },
    },
  },
  data() {
    return {
      sendEmail: false,
      loading: false,
      fail: false,
      form: {
        email: '',
      },
      rules: {
        email: [
          {
            required: true,
            message: this.$t('validation_email_require'),
            trigger: 'blur',
          },
          {
            type: 'email',
            required: true,
            message: this.$t('validation_email'),
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          this.loading = true;
          this.fail = false;

          if (this.globalRecaptcha.enabled) {
            this.$refs.invisibleRecaptcha.execute();
          } else {
            await this.resetPassword(null);
          }
        }
      });
    },
    async resetPassword(reCaptchaKey) {
      const dataForm = {
        ...this.form,
        'g-recaptcha-response': reCaptchaKey || null,
      };

      this.$http.post('/resetting', dataForm).then(
        response => {
          this.loading = false;
          this.sendEmail = true;
        },
        error => {
          this.fail = true;
          this.loading = false;
        }
      );

      if (this.$refs.invisibleRecaptcha !== undefined) {
        this.$refs.invisibleRecaptcha.reset();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.login-form {
  margin: 0 auto;
  width: 273px;
  background: white;
  padding: 30px 60px 15px 60px;
  color: #424c61;
  box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6), 0 2px 4px 0 rgba(232, 237, 250, 0.5);

  @media screen and (max-width: 500px) {
    padding: 15px 30px 15px 30px;
  }

  &__fail {
    margin: 0 0 -5px 0;
  }

  &__success {
    text-align: center;

    &-row {
      margin: -5px 0px 20px 0px;
    }
  }
  &__form-item,
  &__row {
    margin-bottom: 0px;
  }

  &__form {
    margin: 15px 0 0 0;

    &-item__row {
      margin-bottom: 15px;
    }
  }

  &__button {
    width: 100%;
  }
}

.login-title {
  text-align: center;
  font-size: 22px;
  margin-bottom: -5px;
}

.ElRow {
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}

.ElCol {
  border-radius: 4px;
}

.text-center {
  text-align: center;
}

.success-message {
  line-height: 1.6em;
  word-break: break-word;
}
</style>
