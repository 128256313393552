<template>
  <div>
    <ElRow type="flex" justify="center">
      <div class="login-form">
        <ElRow type="flex" justify="center">
          <ElCol class="login-title">{{ $t('login') }}</ElCol>
        </ElRow>

        <FadeTransition>
          <ElRow style="margin: 0" v-show="failLogin" :key="0">
            <ElCol type="flex" justify="center" style="margin: 0 0 -10px 0">
              <p class="fail-login">{{ loginErrorMessageText }}</p>
            </ElCol>
          </ElRow>
        </FadeTransition>

        <ElRow style="margin-bottom: 20px">
          <ElForm
            :model="form"
            :rules="rules"
            ref="form"
            style="margin: 15px 0 0"
            @submit.prevent="onSubmit('form')"
          >
            <ElFormItem prop="email">
              <ElInput
                id="email"
                v-model="form.email"
                placeholder="Email"
                @keyup.native="handleFormInput"
              />
            </ElFormItem>

            <ElFormItem prop="password">
              <ElInput
                id="password"
                type="password"
                v-model="form.password"
                :placeholder="this.$t('password')"
                @keyup.native.enter="submitForm('form')"
                @keyup.native="handleFormInput"
              />

              <RouterLink to="/reset" class="forgot-password" id="forgot">{{
                $t('forgot')
              }}</RouterLink>
            </ElFormItem>

            <ElFormItem style="margin-bottom: 11px">
              <ElRow type="flex" justify="space-between">
                <ElCol :span="24">
                  <ElButton
                    id="submit"
                    style="width:100%;"
                    type="primary"
                    class="enter-button"
                    @click="submitForm('form')"
                    :loading="loading"
                    >{{ $t('sign_in') }}</ElButton
                  >

                  <VueRecaptcha
                    v-if="globalRecaptcha.enabled && globalRecaptcha.key"
                    ref="recaptcha"
                    size="invisible"
                    loadRecaptchaScript
                    @verify="login"
                    @expired="submitForm('form')"
                    :sitekey="globalRecaptcha.key"
                  ></VueRecaptcha>
                </ElCol>
              </ElRow>
            </ElFormItem>

            <ElFormItem v-if="registration">
              <ElRow type="flex" justify="center">
                <RouterLink to="/register" class="register" id="register">{{
                  $t('registration')
                }}</RouterLink>
              </ElRow>
            </ElFormItem>
          </ElForm>
        </ElRow>
      </div>
    </ElRow>
  </div>
</template>

<script>
import urls from '../pages/urls';
import mirrorKey from 'mirrorkey';
import { FadeTransition } from 'vue2-transitions';
import sessionKeys from '@/sessionStorageKeys';
import VueRecaptcha from 'vue-recaptcha';

const loginFailReasons = mirrorKey({
  FAIL_BACKEND: null,
  EMAIL_NOT_CONFIRMED: null,
  DEFAULT: null,
});

export default {
  components: {
    FadeTransition,
    VueRecaptcha,
  },
  i18n: {
    messages: {
      ru: {
        social_login: 'Войти при помощи социальных сетей',
        or: 'или',
        sign_in: 'Войти',
        dont_have_account: 'Еще нет аккаунта?',
        registration: 'Зарегистрироваться',
        invalid_credentials: 'Неверный логин или пароль',
        account_not_active:
          'Пожалуйста, активируйте свой аккаунт, используя ссылку для подтверждения, отправленную на ваш электронный адрес',
        oauth_email_is_not_provided:
          'Ваш аккаунт не содержит информации о вашей почте, пожалуйста используйте другой аккаунт для входа или зарегестрируйтесь',
      },
      uk: {
        social_login: 'Увійти за допомогою соціальних мереж',
        or: 'або',
        sign_in: 'Увійти',
        dont_have_account: 'Ще немає аккаунта?',
        registration: 'Зареєструватися',
        invalid_credentials: 'Неправильний логін або пароль',
        account_not_active:
          'Активуйте свій обліковий запис за допомогою посилання для підтвердження, надісланого на вашу електронну пошту',
        oauth_email_is_not_provided:
          'Ваш акаунт не має інформації про вашу пошту, будь ласка використайте інший акаунт для входу або зарейструйтесь',
      },
      en: {
        social_login: 'Sign in with social networks',
        or: 'or',
        sign_in: 'Sign in',
        dont_have_account: "Don't have an account?",
        registration: 'Sign up',
        invalid_credentials: 'Incorrect login or password',
        account_not_active:
          'Please activate your account using a confirmation link sent to your email',
        oauth_email_is_not_provided:
          'Your account does not contain information about your email, please use another one to sign in or sign up',
      },
    },
  },

  created() {
    if (localStorage.getItem('userEmail')) {
      this.form.email = localStorage.getItem('userEmail') || this.$session.get(sessionKeys.EMAIL);
      localStorage.removeItem('userEmail');
    }

    if (localStorage.getItem('password')) {
      this.form.password = localStorage.getItem('password');
      localStorage.removeItem('password');
    }

    this.form.email = this.$session.get(sessionKeys.EMAIL) || this.form.email;
    this.inviteToken = this.$session.get(sessionKeys.TOKEN) || this.$route.query.token || '';
  },

  watch: {
    $route(value) {
      if (value.hash.indexOf('emailIsNotProvided') !== -1) {
        this.$message({
          showClose: true,
          duration: 0,
          message: this.$t('oauth_email_is_not_provided'),
          type: 'error',
        });
      }
    },
  },
  data() {
    return {
      formState: null,
      failLogin: false,
      loginFailReason: loginFailReasons.DEFAULT,
      clearingLoginFailLocked: null,
      loading: false,
      inviteToken: '',
      form: {
        email: '',
        password: '',
      },
      googleConnect: urls.googleConnect,
      facebookConnect: urls.facebookConnect,
      githubConnect: urls.githubConnect,
      rules: {
        email: [
          {
            required: true,
            message: this.$t('validation_email_require'),
            trigger: 'blur',
          },
          {
            type: 'email',
            required: true,
            message: this.$t('validation_email'),
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: this.$t('validation_password'),
            trigger: 'blur',
          },
          {
            range: true,
            min: 6,
            message: this.$t('validation_password_length', { n: 6 }),
            trigger: 'blur',
          },
        ],
      },
    };
  },

  computed: {
    loginErrorMessageText() {
      let translationKey;

      switch (this.loginFailReason) {
        case loginFailReasons.FAIL_BACKEND:
          translationKey = 'server_failure';
          break;
        case loginFailReasons.EMAIL_NOT_CONFIRMED:
          translationKey = 'account_not_active';
          break;
        default:
          translationKey = 'invalid_credentials';
          break;
      }

      return this.$t(translationKey);
    },

    registration() {
      return !window.APP_DATA.preferences.registration_by_token;
    },
  },

  methods: {
    saveFormState() {
      const { email, password } = this.form;
      this.formState = JSON.stringify({ email, password });
    },

    getCurrentFormState() {
      const { email, password } = this.form;
      return JSON.stringify({ email, password });
    },

    handleFormInput() {
      if (this.failLogin && this.formState !== this.getCurrentFormState()) {
        this.failLogin = false;
        this.saveFormState();
      }
    },

    openAuthWindow(event) {
      const left = screen.width / 2;
      const top = screen.height / 2;

      window.open(
        event.target.href,
        'Authenticate',
        `width=412, height=756, top=${top}, left=${left}`
      );
      event.preventDefault();
    },

    submitForm(formName) {
      this.failLogin = false;

      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.globalRecaptcha.enabled && this.globalRecaptcha.key) {
            this.$refs.recaptcha.execute();
          } else {
            this.login(null);
          }
        }
      });
    },

    login(reCaptchaKey) {
      this.loading = true;

      let data = {
        email: this.form.email,
        password: this.form.password,
        'g-recaptcha-response': reCaptchaKey || null,
      };

      this.$http
        .post('/login_check', data, {
          validateStatus(status) {
            return status >= 200 && status < 500;
          },
        })
        .then(response => {
          this.loading = false;
          const { data } = response;

          if (data.need2FA) {
            this.$router.push({ name: '2fa' });

            return;
          }

          if (data.success) {
            this.processLoginSucces(data);
          } else {
            this.processLoginError(response);
          }

          this.saveFormState();
        });

      if (this.$refs.recaptcha !== undefined) {
        this.$refs.recaptcha.reset();
      }
    },

    processLoginSucces(data) {
      this.failLogin = false;

      let redirectTo;

      if (data.hasOwnProperty('redirectTo')) {
        redirectTo = data.redirectTo;
      } else if (this.inviteToken) {
        redirectTo = `/?token=${this.inviteToken}`;
        this.$session.remove(sessionKeys.TOKEN);
      } else {
        redirectTo = '/';
      }

      location.href = redirectTo;
    },

    processLoginError(response) {
      this.failLogin = true;

      if (response.code === 500) {
        this.loginFailReason = loginFailReasons.FAIL_BACKEND;
      } else {
        if (response.data.message === 'Email not confirmed') {
          this.loginFailReason = loginFailReasons.EMAIL_NOT_CONFIRMED;
        } else {
          this.loginFailReason = loginFailReasons.DEFAULT;
          this.form.password = '';
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.forgot-password {
  display: inline-block;
  position: absolute;
  right: 0;
  padding: 6px 7px;
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
  -moz-transform: translateY(-65%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  font-size: 14px;
}

.span-or {
  display: block;
  position: absolute;
  left: 50%;
  top: -9px;
  margin-left: -1.5em;
  background-color: white;
  width: 3em;
  text-align: center;
}

.hr {
  background: #c6c6c6;
  height: 1px;
  border: none;
  margin: 0.5em 0;
  position: relative;
}

.fail-login {
  color: #ff4949;
  font-size: 14px;
  text-align: center;
}

.login-form {
  margin: 0 auto;
  width: 273px;
  background: white;
  padding: 30px 60px 15px 60px;
  color: #424c61;
  box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6), 0 2px 4px 0 rgba(232, 237, 250, 0.5);

  @media screen and (max-width: 500px) {
    padding: 15px 30px 15px 30px;
  }
}

.login-title {
  text-align: center;
  font-size: 22px;
  margin-bottom: -5px;
}

.ElRow {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.ElCol {
  border-radius: 4px;
}

.text-center {
  text-align: center;
}

.social-icon > div {
  width: auto;
}

.fa {
  padding: 5px 15px;
  font-size: 30px;
  width: 50px;
  text-align: center;
  text-decoration: none;
}

.fa:hover {
  opacity: 0.7;
}

.fa-facebook {
  background: #3b5998;
  color: white;
}

.fa-google {
  background: #dd4b39;
  color: white;
}

.fa-github {
  background: #333333;
  color: white;
}
</style>
