<template>
  <div class="confirm-registration" v-loading="pageLoading">
    <template v-if="confirmationSucceeded">
      <div class="confirm-registration__success-icon">
        <success-icon />
      </div>

      <p class="confirm-registration__message">
        {{ $t('confirm_user_success') }}
      </p>

      <div class="confirm-registration__login-btn-wrap">
        <el-button type="primary" @click="goToLogin" class="confirm-registration__login-btn">
          {{ $t('sign_in[0]') }}
        </el-button>
      </div>
    </template>

    <template v-if="confirmationFailed">
      <p class="confirm-registration__message">
        {{ $t('link_is_not_active') }}
      </p>

      <div class="confirm-registration__controls-wrap">
        <p class="confirm-registration__controls-message">
          {{ $t('return_to_login') }}
        </p>
        <el-button class="confirm-registration__login-btn-text" type="text" @click="goToLogin">
          {{ $t('sign_in[1]') }}
        </el-button>
      </div>
    </template>
  </div>
</template>

<script>
import SuccessIcon from '@/components/Success';

export default {
  created() {
    this.confirmRegistration();
  },

  data() {
    return {
      CONFIRMATION_URL: '/registration/confirmation',
      pageLoading: false,
      confirmationFailed: null,
      confirmationSucceeded: null,
    };
  },

  methods: {
    async confirmRegistration() {
      try {
        this.pageLoading = true;

        const { token } = this.$route.params;
        await this.$http.post(this.CONFIRMATION_URL, { token });
        this.confirmationSucceeded = true;
      } catch (e) {
        this.confirmationFailed = true;
      } finally {
        this.pageLoading = false;
      }
    },

    goToLogin() {
      this.$router.push({ name: 'login' });
    },
  },

  i18n: {
    messages: {
      ru: {
        sign_in: ['Войти', 'Входa'],
        link_is_not_active: 'Ваш аккаунт уже активирован',
        confirm_account: 'Потверждение аккаунта',
        return_to_login: 'Вернуться на страницу',
        confirm_user_success: `Ваш аккаунт был успешно активирован. Войдите, чтобы начать работу с ${window.APP_DATA.appName}.`,
      },
      uk: {
        sign_in: ['Увійти', 'Входу'],
        confirm_account: 'Подтверждение аккаунта',
        return_to_login: 'Повернутися на сторінку',
        link_is_not_active: 'Ваш обліковий запис вже активовано',
        confirm_user_success: `Ваш обліковий запис успішно активовано. Увійдіть, щоб розпочати роботу з ${window.APP_DATA.appName}.`,
      },
      en: {
        sign_in: ['Login', 'Login'],
        confirm_account: 'Account verification',
        return_to_login: 'Return to the page',
        link_is_not_active: 'Your account is already activated',
        confirm_user_success: `Your account has been successfully activated. Go ahead to sign in to get started with ${window.APP_DATA.appName}.`,
      },
    },
  },

  components: {
    SuccessIcon,
  },
};
</script>

<style lang="scss" scoped>
.confirm-registration {
  margin: 0 auto;
  width: 273px;
  min-height: 120px;
  background: #fff;
  padding: 30px 60px 15px 60px;
  color: #424c61;
  box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6), 0 2px 4px 0 rgba(232, 237, 250, 0.5);

  @media screen and (max-width: 500px) {
    padding: 15px 30px 15px 30px;
  }

  &__stub {
    height: 200px;
  }

  &__success-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &__message {
    text-align: center;
    margin: 20px 0;
  }

  &__login-btn-wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px 0;
  }

  &__controls-wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;

    > * + * {
      margin-left: 4px;
    }
  }

  &__login-btn {
    width: 100%;
  }

  &__login-btn-text {
    color: #1c5acb !important;
  }

  &__controls-message {
    font-size: 14px;
  }

  &__title {
    font-size: 22px;
    font-weight: 400;
    text-align: center;
    margin-top: 0;
  }
}
</style>
