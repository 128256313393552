import mirrorKey from 'mirrorkey';

const lsEvents = mirrorKey({
  /**
   * Emitted by dashboard
   */
  DASHBOARD_INITIALIZED: null,
});

export default lsEvents;
