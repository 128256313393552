import Vue from 'vue';
import loadingVue from './loading.vue';
import merge from 'element-ui/src/utils/merge';


const LoadingConstructor = Vue.extend(loadingVue);
const defaults = {
  text: null,
  fullscreen: true,
  body: false,
  lock: false,
  customClass: ''
};
let fullscreenLoading = null;


LoadingConstructor.prototype.originalPosition = '';
LoadingConstructor.prototype.originalOverflow = '';


LoadingConstructor.prototype.close = function close () {
  const CONTEXT = this;
  if (CONTEXT.fullscreen && CONTEXT.originalOverflow !== 'hidden') {
    document.body.style.overflow = CONTEXT.originalOverflow;
  }

  if (CONTEXT.fullscreen || CONTEXT.body) {
    document.body.style.position = CONTEXT.originalPosition;
  }
  else {
    CONTEXT.target.style.position = CONTEXT.originalPosition;
  }

  if (CONTEXT.fullscreen) {
    fullscreenLoading = null;
  }

  CONTEXT.$on('after-leave', () => {
    if (CONTEXT.$el && CONTEXT.$el.parentNode) {
      CONTEXT.$el.parentNode.removeChild(CONTEXT.$el);
    }

    CONTEXT.$destroy();
  });
  CONTEXT.visible = false;
};


const addStyle = (options, parent, instance) => {
  const maskStyle = {};
  if (options.fullscreen) {
    instance.originalPosition = document.body.style.position;
    instance.originalOverflow = document.body.style.overflow;
  }
  else if (options.body) {
    instance.originalPosition = document.body.style.position;

    [
      'top',
      'left'
    ].forEach((property) => {
      const scroll = property === 'top' ? 'scrollTop' : 'scrollLeft';
      maskStyle[property] = `${options.target.getBoundingClientRect()[property] +
        document.body[scroll] +
        document.documentElement[scroll]
      }px`;
    });

    [
      'height',
      'width'
    ].forEach((property) => {
      maskStyle[property] = `${options.target.getBoundingClientRect()[property]}px`;
    });
  }
  else {
    instance.originalPosition = parent.style.position;
  }

  Object.keys(maskStyle).forEach((property) => {
    instance.$el.style[property] = maskStyle[property];
  });
};


function loadingFirstCalculated (opts) {
  if (typeof opts.target === 'string') {
    opts.target = document.querySelector(opts.target);
  }

  opts.target = opts.target || document.body;
  if (opts.target !== document.body) {
    opts.fullscreen = false;
  }
  else {
    opts.body = true;
  }

  return opts;
}


function partOfSecondPart (opts, parent, instance) {
  if (instance.originalPosition !== 'absolute') {
    parent.style.position = 'relative';
  }

  if (opts.fullscreen && opts.lock) {
    parent.style.overflow = 'hidden';
  }

  parent.appendChild(instance.$el);
  Vue.nextTick(() => {
    instance.visible = true;
  });
  if (opts.fullscreen) {
    fullscreenLoading = instance;
  }
}


function loadingSecondCalculated (opts) {
  const parent = opts.body ? document.body : opts.target;
  const instance = new LoadingConstructor({
    el: document.createElement('div'),
    data: opts
  });

  addStyle(opts, parent, instance);
  partOfSecondPart(opts, parent, instance);

  return instance;
}


function Loading (options = {}) {
  if (Vue.prototype.$isServer) {
    return;
  }

  let opts = merge({}, defaults, options);
  opts = loadingFirstCalculated(opts);

  return opts.fullscreen && fullscreenLoading ? fullscreenLoading : loadingSecondCalculated(opts);
}

export default Loading;
