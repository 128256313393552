<template>
  <div>
    <ElRow type="flex" justify="center">
      <div class="login-form" v-loading="loading">
        <ElRow type="flex" justify="center">
          <ElCol class="login-title">
            {{ $t('two_step_verification') }}
          </ElCol>
        </ElRow>
        <ElForm
          @submit.native.prevent="submitForm('form')"
          :model="form"
          :rules="rules"
          ref="form"
          class="two-step__form"
          @submit.prevent="onSubmit('form')"
        >
          <ElFormItem prop="auth_code">
            <ElInput v-model="form.auth_code" ref="codeInput" :placeholder="$t('auth_code')" />

            <Transition name="el-fade-in">
              <ElRow class="two-step__transition-row" v-show="failLogin">
                <ElCol type="flex" justify="center" class="two-step__transition-col">
                  <p class="fail-login">{{ $t('code_validation_failure') }}</p>
                </ElCol>
              </ElRow>
            </Transition>
          </ElFormItem>
          <ElFormItem class="two-step__item">
            <ElRow type="flex" justify="space-between">
              <ElCol :span="24">
                <ElButton
                  type="primary"
                  class="enter-button two-step__button"
                  @click="submitForm('form')"
                >
                  {{ $t('verify') }}
                </ElButton>
              </ElCol>
            </ElRow>
          </ElFormItem>
        </ElForm>
        <ElRow justify="center">
          <ElCol :span="24" class="return-to-login-page two-step__return">
            {{ $t('return_to_login') }}
            <a href="/logout">
              {{ $t('sign_in') }}
            </a>
          </ElCol>
        </ElRow>
      </div>
    </ElRow>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$refs.codeInput.focus();
  },

  i18n: {
    messages: {
      ru: {
        sign_in: 'страницу Входa',
        return_to_login: 'Вернуться на ',
        auth_code: 'Код аутентификации',
        cancel: 'Отменить',
        verify: 'Подтвердить',
        validation_key: 'Введите код',
        code_validation_failure: 'Код недействительный',
      },
      uk: {
        sign_in: 'сторінку Входу',
        return_to_login: 'Повернутися на ',
        auth_code: 'Код аутнетифікації',
        cancel: 'Відмінити',
        verify: 'Підтвердити',
        validation_key: 'Введіть код',
        code_validation_failure: 'Код недійсний',
      },
      en: {
        sign_in: 'Login page',
        return_to_login: 'Return to the ',
        auth_code: 'Authentication code',
        cancel: 'Cancel',
        verify: 'Verify',
        validation_key: 'Code is required',
        code_validation_failure: 'Code is invalid',
      },
    },
  },
  data() {
    return {
      loading: false,
      failLogin: false,
      form: {
        auth_code: '',
      },
      rules: {
        auth_code: [{ required: true, message: this.$t('validation_key'), trigger: 'blur' }],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid === false) {
          return;
        }

        let formData = new FormData();
        formData.append('auth_code', this.form.auth_code);
        this.loading = true;
        this.$http
          .post('/2fa_check', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            validateStatus(status) {
              return status >= 200 && status < 500;
            },
          })
          .then(response => {
            const data = response.data;

            if (undefined !== data.success && data.success === true) {
              let locationHref = '/';
              if (data.hasOwnProperty('redirectTo')) {
                locationHref = data.redirectTo;
              }

              location.href = locationHref;

              return;
            }

            if (data.error && data.error === 'code_invalid') {
              this.$refs.form.clearValidate();
              this.$message({ type: 'error', message: this.$t('code_validation_failure') });
              this.$refs.codeInput.focus();
            }

            if (response.status === 403) {
              this.$message({ type: 'error', message: this.$t('code_validation_failure') });
            }

            this.form.auth_code = '';
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.two-step {
  &__form {
    margin-top: 15px;
  }

  &__transition {
    &-col {
      margin: 0 0 -10px 0px;
    }
    &-row {
      margin: 0;
    }
  }

  &__item {
    margin-bottom: 0px;
  }

  &__button {
    width: 100%;
  }

  &__return {
    text-align: center;
    margin-top: 20px;
  }
}

.hr {
  background: #c6c6c6;
  height: 1px;
  border: none;
}

.login-form {
  margin: 0 auto;
  width: 273px;
  background: white;
  padding: 30px 60px 15px 60px;
  color: #424c61;
  box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6), 0 2px 4px 0 rgba(232, 237, 250, 0.5);

  @media screen and (max-width: 500px) {
    padding: 15px 30px 15px 30px;
  }
}

.login-title {
  text-align: center;
  font-size: 22px;
  margin-bottom: -5px;
}

.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}
.fail-login {
  color: #ff4949;
  font-size: 14px;
  text-align: center;
}

.return-to-login-page {
  font-size: 14px;
  padding-top: 10px;
  margin-bottom: 10px;
}
</style>
