<template>
  <div class="app">
    <div class="container">
      <el-row>
        <el-col>
          <header-bar/>
        </el-col>
      </el-row>
      <el-row>
        <router-view/>
      </el-row>
      <el-row>
        <footer-bar/>
      </el-row>
    </div>

    <notification-handler/>
  </div>
</template>

<script>
import HeaderBar from "@/components/Header.vue";
import FooterBar from "@/components/Footer.vue";
import NotificationHandler from '@/components/NotificationHandler'

export default {
  data() {
    return {
      msg: "Paycore dashboard",
    };
  },
  created() {
    if (window.need2FA) {
      this.$router.push({ name: "2fa" });
    }
  },

  components: {
    NotificationHandler,
    HeaderBar,
    FooterBar,
  }
};
</script>

<style>
body {
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif;
  background: #f7f8fa;
}

a {
  color: #1c5acb;
}

.container {
  margin: 0 auto;
}

.grecaptcha-badge {
  display: none;
}

a {
  text-decoration: none;
}

.form-footer-text-block {
  font-size: 14px;
  margin: 15px 0;
}

#password {
  padding-right: 70px;
}
</style>
