<template>
  <transition name="el-loading-fade" @after-leave="handleAfterLeave">
    <div
      v-show="visible"
      class="el-loading-mask"
      :class="[customClass, { 'is-fullscreen': fullscreen }]">
      <div class="el-loading-spinner">
        <svg class="circular"
             width="32px"
             height="32px"
             viewBox="0 0 32 32"
             version="1.1"
             xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink">

          <g id="Modal" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="01_mobile-views" transform="translate(-224.000000, -1660.000000)">
              <g id="w-basic" transform="translate(80.000000, 1374.000000)">
                <g id="spinner" transform="translate(141.000000, 283.000000)">
                  <g transform="
                      translate(19.000000, 19.000000) rotate(10.000000)
                      translate(-19.000000, -19.000000) translate(3.000000, 3.000000)">
                    <!-- eslint-disable-next-line -->
                    <path d="M16,2.84217094e-14 C7.16150109,2.84217094e-14 5.68434189e-14,7.163444 5.68434189e-14,16 C5.68434189e-14,24.8384989 7.163444,32 16,32 C24.8384989,32 32,24.836556 32,16 L26,16 C26,21.5228475 21.5240618,26 16,26 C10.4771525,26 6,21.5240618 6,16 C6,10.4771525 10.4759382,6 16,6 L16,1.38333789e-13 Z" id="Combined-Shape" fill="#0055CC"></path>
                    <circle id="Oval" fill="#F8D31B" cx="15.8922367" cy="16.0744396" r="3.5"></circle>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
        <p v-if="text" class="el-loading-text">{{ text }}</p>
      </div>
    </div>
  </transition>
</template>


<script>
export default {

  data () {
    return {
      text: null,
      fullscreen: true,
      visible: false,
      customClass: ''
    };
  },

  methods: {
    handleAfterLeave () {
      this.$emit('after-leave');
    }
  }

};
</script>
