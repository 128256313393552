import Vue from 'vue';
import * as Sentry from '@sentry/vue';

export default function (router) {
  if (!!FRONT_SENTRY_DNS && !!FRONT_SENTRY_RELEASE) {
    const tags = {
      theme: 'default',
      cell: window.APP_DATA.application_cell,
      app: 'entrance',
    };

    Sentry.init({
      Vue,
      dsn: FRONT_SENTRY_DNS,
      environment: window.APP_DATA.environment,
      release: FRONT_SENTRY_RELEASE,
      integrations: [Sentry.browserTracingIntegration({ router })],
    });

    // We substitute the tags through the function, because if they are passed in the settings, they will not be applied
    Sentry.setTags(tags);
  }
}
