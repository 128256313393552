<template>
  <ElRow type="flex" justify="center" class="footer">
    <ul>
      <li class="first">
        <a @click="changeLanguage('ru')">{{ $t('russian') }}</a>
      </li>
      <li>
        <a @click="changeLanguage('en')">{{ $t('english') }}</a>
      </li>
      <li>
        <a @click="changeLanguage('uk')">{{ $t('ukrainian') }}</a>
      </li>
    </ul>
  </ElRow>
</template>
<script>
export default {
  methods: {
    changeLanguage(lang) {
      this.$cookies.set('user_language', lang);
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  padding: 20px 0;

  ul {
    margin: 0;
    outline: 0;
    padding: 0;
  }

  li {
    display: inline;
    padding: 0 20px 0 16px;
    background-size: 4px 5px;
    background-position: 100% 9px;
    background-repeat: no-repeat;
  }

  a {
    color: #c7cdd6;
    font-weight: normal;
    font-size: 12px;
    text-shadow: 0 1px 0 hsla(0, 0%, 100%, 0.5);
  }

  a:hover {
    cursor: pointer;
    color: #798391;
  }
}
</style>
