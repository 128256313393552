import enLocale from 'element-ui/lib/locale/lang/en';
import ruLocale from 'element-ui/lib/locale/lang/ru-RU';
import uaLocale from 'element-ui/lib/locale/lang/ua';
import VueI18n from 'vue-i18n';
import Vue from 'vue';

const messages = {
  ru: {
    english: 'Английский язык',
    russian: 'Русский язык',
    ukrainian: 'Украинский язык',
    two_step_verification: 'Двухэтапная верификация',
    login: 'Вход',
    validation_email_require: 'Введите адрес электронной почты',
    validation_email: 'Введите корректный адрес электронной почты',
    validation_password: 'Введите пароль',
    validation_password_length: 'Пароль должен быть не менее {n} символов',
    password: 'Пароль',
    confirm_password: 'Подтвердите пароль',
    passwords_must_match: 'Пароли должны совпадать',
    forgot: 'Забыли?',
    server_validation_failure: 'Проверьте поля и попробуйте снова',
    server_failure: 'Внутренняя ошибка, попробуйте снова позже',
    cancel_modal_dialog: 'Отменить',
    create_modal_dialog: 'Создать',
    name_required_modal_dialog: 'имя является обязательным',
    name_should_be_modal_dialog: 'имя должно содержать от 2 до 100 символов',
    session_expired__title: 'Сессия истекла',
    session_expired__message: 'Авторизуйтесь повторно',
    route__login: 'Вход',
    route__registration: 'Регистрация',
    route__reset_password: 'Восстановление пароля',
    route__2fa: 'Двухфакторная аутентификация',
    ...ruLocale,
  },
  uk: {
    english: 'Англійська мова',
    russian: 'Російська мова',
    ukrainian: 'Українська мова',
    two_step_verification: 'Двоетапна веріфікація',
    login: 'Вхід',
    validation_email_require: 'Введіть адресу електронної пошти',
    validation_email: 'Введіть коректну адресу електронної пошти',
    validation_password: 'Введіть пароль',
    validation_password_length: 'Пароль повинен бути не менше {n} символів',
    password: 'Пароль',
    confirm_password: 'Підтвердіть пароль',
    passwords_must_match: 'Паролі повинні співпадати',
    forgot: 'Забули?',
    server_validation_failure: 'Перевірте поля і спробуйте знову',
    server_failure: 'Внутрішня помилка, спробуйте знову пізніше',
    cancel_modal_dialog: 'Відмінити',
    create_modal_dialog: 'Створити',
    name_required_modal_dialog: "ім'я є обов'язковим",
    name_should_be_modal_dialog: "ім'я має містити від 2 до 100 символів",
    session_expired__title: 'Сесію було закрито',
    session_expired__message: 'Необхідно повторно авторизуватись',
    route__login: 'Вход',
    route__registration: 'Реєстрація',
    route__reset_password: 'Відновлення паролю',
    route__2fa: 'Двофакторна аутентифікація',
    ...uaLocale,
  },
  en: {
    english: 'English',
    russian: 'Russian',
    ukrainian: 'Ukrainian',
    two_step_verification: 'Two-step verification',
    login: 'Sign in',
    server_failure: 'Internal server error. Try again later...',
    validation_email_require: 'Please input email address',
    validation_email: 'Please input correct email address',
    validation_password: 'Please input the password',
    validation_password_length: 'Password must be at least {n} characters',
    email: 'Email',
    password: 'Password',
    confirm_password: 'Confirm password',
    passwords_must_match: 'Passwords must match',
    forgot: 'Forgot?',
    server_validation_failure: 'Check the fields and try again',
    cancel_modal_dialog: 'Cancel',
    create_modal_dialog: 'Create',
    name_required_modal_dialog: 'name is required',
    name_should_be_modal_dialog: 'name should be from 2 to 100',
    session_expired__title: 'Session expired',
    session_expired__message: 'Login again please',
    route__login: 'Login',
    route__registration: 'Registration',
    route__reset_password: 'Password reset',
    route__2fa: '2FA',
    ...enLocale,
  },
};

Vue.use(VueI18n);

export const i18n = new VueI18n({
  silentTranslationWarn: true,
  fallbackLocale: 'en',
  locale: window.APP_DATA.language || 'en',
  messages,
});

export default i18n;
