<template>
  <div>
    <el-row type="flex" justify="center">
      <div class="login-form" v-loading.body="loadingUser">
        <div v-if="confirmUserSuccess" style="text-align: center">
          <success-button></success-button>
          <hr class="hr" style="margin-bottom: 5px" />
          <el-row type="flex" justify="center" style="margin: -5px 0px 20px 0px">
            <el-col :span="24" class="text-center">
              <span class="text-center">
                <br />
                <span
                  v-html="$t('confirm_user_success')"
                  style="line-height: 1.5em; margin: 10px 0 0; display: inline-block"
                >
                </span>
              </span>
            </el-col>
          </el-row>
          <hr class="hr" />
          <el-row type="flex" justify="center" style="margin: 20px 0px 10px 0px">
            <el-col :span="24">
              <router-link to="/login">
                <el-button id="login" type="primary" class="enter-button" style="width: 100%">
                  {{ $t('login') }}
                </el-button>
              </router-link>
            </el-col>
          </el-row>
        </div>

        <el-row type="flex" justify="center" v-if="confirmUserSuccess === false">
          <el-col class="login-title">
            {{ $t('confirm_account') }}
          </el-col>
        </el-row>
        <div v-if="failLoadUser" style="text-align: center">
          <hr class="hr" />
          <div style="margin: 20px">
            {{ $t('link_is_not_active') }}
          </div>
          <hr class="hr" />
          <el-row type="flex" justify="center">
            <el-col class="text-center form-footer-text-block">
              {{ $t('return_to_login') }}
              <router-link to="/login">{{ $t('sign_in') }}</router-link>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-row>
  </div>
</template>

<script>
import SuccessButton from '../components/Success.vue';

export default {
  components: { SuccessButton },
  i18n: {
    messages: {
      ru: {
        sign_in: 'Входa',
        link_is_not_active: 'Данная ссылка не активна',
        confirm_account: 'Потверждение аккаунта',
        return_to_login: 'Вернуться на страницу',
        confirm_user_success: 'Ваш аккаунт был успешно активирован. Войдите, чтобы начать работу.',
      },
      uk: {
        sign_in: 'Входу',
        confirm_account: 'Підтвердження аккаунту',
        return_to_login: 'Повернутися на сторінку',
        link_is_not_active: 'Дане посилання не активне',
        confirm_user_success:
          'Ваш аккаунт був успішно активований. Увійдіть, щоб розпочати роботу.',
      },
      en: {
        sign_in: 'Login',
        confirm_account: 'Account verification',
        return_to_login: 'Return to the page',
        link_is_not_active: 'This link is not active',
        confirm_user_success:
          'Your account has been successfully activated. Go ahead to sign in to get started.',
      },
    },
  },
  data() {
    return {
      confirmUserSuccess: false,
      confirmToken: null,
      loadingUser: true,
      failLoadUser: false,
      submitLoading: false,
      fail: false,
      email: '',
    };
  },
  methods: {
    confirmUser() {
      this.$http
        .post(`/register/confirm/${this.confirmToken}`, this.form)
        .then(
          response => {
            this.confirmUserSuccess = true;
            response = response.data.data;
            this.email = response.userEmail;
            localStorage.setItem('userEmail', this.email);
          },
          error => {
            this.failLoadUser = true;
          }
        )
        .then(() => {
          this.submitLoading = false;
        });
    },
    findUserByToken(token) {
      this.$http
        .get(`/register/confirm/${token}`)
        .then(
          response => {
            this.confirmUser();
          },
          error => {
            this.failLoadUser = true;
          }
        )
        .then(data => {
          this.loadingUser = false;
        });
    },
  },
  created() {
    this.confirmToken = this.$route.params.token || null;
    this.findUserByToken(this.confirmToken);
  },
};
</script>

<style scoped lang="scss">
.hr {
  background: #c6c6c6;
  height: 1px;
  border: none;
}

.fail-login {
  color: #ff4949;
  font-size: 14px;
  text-align: center;
}

.login-form {
  margin: 0 auto;
  width: 273px;
  background: white;
  padding: 30px 60px 15px 60px;
  color: #424c61;
  box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6), 0 2px 4px 0 rgba(232, 237, 250, 0.5);

  @media screen and (max-width: 500px) {
    padding: 15px 30px 15px 30px;
  }
}

.login-title {
  text-align: center;
  font-size: 22px;
  margin-bottom: -5px;
}

.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}

.text-center {
  text-align: center;
}
</style>
