import Vue from 'vue';

export default {
  init() {
    Vue.mixin({
      data() {
        return {
          get globalRecaptcha() {
            return window.APP_DATA.recaptcha || { key: '', enabled: false };
          },
          get locale() {
            return window.language || 'ru';
          },
        };
      },
    });
  },
};
