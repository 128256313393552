<template>
  <div v-if="logoUrl" class="logo-block">
    <img class="logo center" :src="logoUrl" title="Application" />
  </div>
</template>

<script>
export default {
  computed: {
    logoUrl() {
      return (window.APP_DATA.urls || {}).org_logo;
    },

    staticUrl() {
      return (window.APP_DATA.urls || {}).organization_static;
    },
  },
};
</script>

<style scoped>
.logo-block {
  margin: 20px 0px 150px 0;
}

.logo {
  max-height: 100px;
  max-width: 100%;
}

img.center {
  display: block;
  margin: 0 auto;
}
</style>
